import React from 'react'

function LoginInput({ type = "text", value, onChange, placeholder, id = "", labelText}) {
  return (
    <div className='input-container'>
      { labelText && (<label className="input-login-label" htmlFor={id}>{labelText}</label>) }
    <input
      type={type}
      id={id}
      className="input-login"
      placeholder={placeholder}
      value={value}
      onChange={onChange}
    />
    </div>
  )
}

export default LoginInput