import React from 'react';
import './style.css';
import Menu from '../MenuLoeffa'
import SimpleCard from './SimpleCard'
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';

import './Dashboard.css';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
}));

export default function Dashboard() {
    const classes = useStyles();

    return (
        <div className="report-container">
            <Menu title="Dashboard" />
            <div className="content_main_container">
                <br />
                <div className={classes.root}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Paper className={classes.paper}>
                                <span>
                                    "Estamos a X dias sem piadas ruins"
                                    "Nosso recorde é de X dias sem piadas ruins"
                                    "Nosso total é de X piadas ruins"<br />
                                </span>
                            </Paper>
                        </Grid>
                        <Grid item xs={6}>
                            <SimpleCard image="./images/Loeffa_button.png" alt="Acesso as piadas ruins" name="Botão" />
                        </Grid>
                        <Grid item xs={6}>
                            <SimpleCard image="./images/relogio.png" alt="Agenda de horários semanais" name="Horas Semanais" />
                        </Grid>
                        <Grid item xs={6}>
                            <SimpleCard image="./images/projetos.png" alt="Acesso aos projetos" name="Projetos" />
                        </Grid>
                        <Grid item xs={6}>
                            <SimpleCard image="./images/agenda.png" alt="Agenda Semanal" name="Agenda" />
                        </Grid>
                    </Grid>
                </div>
                <img className="HackThePlanet" alt="" src="https://media.giphy.com/media/FnGJfc18tDDHy/giphy.gif" />
            </div>
        </div>
    );
}