import React from 'react';
import { MenuItem } from 'react-pro-sidebar';
import { useHistory } from 'react-router-dom';

const SidebarItem = ({ label, link, image, alt, onClick = false, containerStyles, imageStyles, labelStyles, isAdminLink = false }) => {
  const history = useHistory();
  const handleClick = () => {
    if (isAdminLink) {
      const newWindow = window.open(link, '_blank');
      if (newWindow) {
        newWindow.opener = null;
      }
    }
    else history.push(link);
  };
  return (
    <MenuItem onClick={handleClick}>
      <div className={containerStyles} onClick={typeof onClick === 'function' ? onClick : null}>
        <img src={image} alt={alt} className={imageStyles} />
        <p className={labelStyles}>{label}</p>
      </div>
    </MenuItem>
  )
};

export default SidebarItem;