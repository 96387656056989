import React from 'react'
import { useHistory } from 'react-router-dom';
import StatusTitle from './StatusTitle'
import StatusButton from './StatusButton'
import StatusDescription from './StatusDescription'

function SentEmail() {
  const history = useHistory();
  const failTitle = "Esqueci a senha"
  const failRequestText = 'Feito! Um e-mail foi enviado com instruções para você redefinir a sua nova senha';
  const failButtonText = "VOLTAR"

  const handleClick = () => {
        history.goBack();
    }
  return (
    <div className='status-container'>
      <StatusTitle titleText={failTitle} />
      <StatusDescription descriptionText={failRequestText} />
      <StatusButton buttonText={failButtonText} handleClick={handleClick} />
    </div>
  )
}

export default SentEmail