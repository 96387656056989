import React from 'react';

const Footer = () => {
  const COMMIT_TAG = process.env.REACT_APP_CODE_VERSION;
  const LOEFFA_URL = 'https://loeffa.com/';
  const newDate = new Date().getFullYear();

  return (
    <div className="footer">
      © Loeffa. Todos os direitos reservados. v {COMMIT_TAG} - @ <a className='loeffa-link' target='blank' href={LOEFFA_URL}>Loeffa</a> {newDate}
    </div>
  );
};

export default Footer;