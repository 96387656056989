import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import ResetPasswordInput from './ResetPasswordInput';
import ResetTitle from './ResetTitle';
import Loading from '../Users/Loading';

import localApi from '../../services/localApi';

import styles from './PasswordReset.module.css'
import './style.css';

export default function PasswordReset() {
  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState([]);
  const history = useHistory();

  const handleChange = ({ target }) => {
    setErrorMessage([]);
    if (target.id === 'password') {
      setPassword(target.value);
    } else if (target.id === 'password2') {
      setPassword2(target.value);
    }
  }

  async function handlePasswordReset(e) {
    e.preventDefault();
    if (password !== password2 || !password || !password2) {
      setErrorMessage(["As senhas não coincidem. Por favor, tente novamente."]);
    } else {
      setIsLoading(true);
      try {
        let search = window.location.search;
        let params = new URLSearchParams(search);
        let token = params.get('token');
        if (token === undefined || token.length === 0) {
          history.push('/passwordreset/failure');
        } else {
          await localApi.post('password_reset/confirm/', {
            "token": token,
            "password": password
          }).then(response => {
            if (response.status === 200) history.push('/passwordreset/success');
            setIsLoading(false);
          }).catch(error => {
            if (error.response.status === 404) {
              setIsLoading(false);
              history.push('/passwordreset/failure');
            }
            else if (error.response.status === 400) {
              setErrorMessage(error.response.data.password);
              setIsLoading(false);
            } else {
              history.push('/passwordreset/failure');
            }
          });
        }
      } catch (err) {
        history.push('/passwordreset/failure');
      }
    }
  }

  return (
    <div className={styles.main_panel_container}>
      <div className={styles.reset_container}>
        {isLoading ? (<Loading />) : (
          <form onSubmit={handlePasswordReset}>

            <ResetTitle />

            <ResetPasswordInput
              type="password"
              labelText="Nova senha"
              id="password"
              value={password}
              placeholder="********"
              onChange={handleChange}
              required
            />
            <ResetPasswordInput
              type="password"
              id="password2"
              placeholder="********"
              labelText="Confirmar a nova senha"
              value={password2}
              onChange={handleChange}
              required
            />
            {errorMessage ? errorMessage?.map((message) => (
              <p key={message} className='error-text'>
                {message}
              </p>)
            ) : (<p className='reminders-text'>
              A sua senha não pode ser parecida com as suas informações pessoais<br />
              Sua senha deve contar pelo menos 8 caracteres<br />
              Sua senha não deve ser comumente usada<br />
              Sua senha não deve ser inteiramente númerica<br />
            </p>)}


            <button className={styles.reset_button} type="submit" onClick={handlePasswordReset}>Trocar Senha</button>
          </form>
        )}
      </div>
    </div>
  );
}