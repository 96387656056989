import React from 'react'
import { useHistory } from 'react-router-dom';
import StatusTitle from './StatusTitle'
import StatusButton from './StatusButton'
import StatusDescription from './StatusDescription'

function FailureRequest() {
  const history = useHistory();
  const failTitle = "Ops! Deu ruim"
  const failRequestText = `Desculpe, mas algo de inesperado ocorreu. Estamos trabalhando para resolver.
Por favor, tente novamente.`;
  const failButtonText = "VOLTAR"

  const handleClick = () => {
        history.goBack();
    }
  return (
    <div className='status-container'>
      <StatusTitle titleText={failTitle} />
      <StatusDescription descriptionText={failRequestText} />
      <StatusButton buttonText={failButtonText} handleClick={handleClick} />
    </div>
  )
}

export default FailureRequest;