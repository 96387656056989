import React from 'react'
import { useParams } from 'react-router-dom';
import FailureRequest from './FailureRequest';
import SuccessReset from './SuccessReset';
import SentEmail from './SentEmail';

import styles from './style.module.css'
import './style.css';

function StatusService() {
  const { status } = useParams();

  const componentsMap = {
    success: <SuccessReset />,
    sent: <SentEmail />
  };
  const componentToRender = componentsMap[status] || <FailureRequest />;
  return (
    <div className={styles.main_panel_container}>
      <div className={styles.status_container}>
        {componentToRender}
      </div>
    </div>

  )
}

export default StatusService;