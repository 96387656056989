import React, { useState, useEffect } from 'react';
import { FiGitlab, FiGlobe, FiCheckSquare } from 'react-icons/fi';
import { DiGoogleDrive } from 'react-icons/di';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import ReactMarkdown from "react-markdown";

import clockifyApi from '../../services/clockifyApi';
import localApi from '../../services/localApi';

import Menu from '../MenuLoeffa'
import './style.css';

export default function Projects() {
  const emptyProject = {
    name: "",
    isEmptyProject: true,
  };
  const [projects, setProjects] = useState([]);
  const [state, setState] = useState("");
  const [modalProject, setModalProject] = useState(emptyProject);

  const workspaceId = "5e472200e4acab6e6c05404c";
  const api_key = localStorage.getItem('api_key');
  const token = localStorage.getItem('token');

  const secondsInOneHour = 3600;

  useEffect(() => {
    loadProjects();
  }, []);

  async function loadProjects() {
    setState("Carregando lista de projetos...");

    let projs = [];
    let auth = 'Token ' + token;
    await localApi.get(`/projects`, {
      headers: {
        Authorization: auth
      }
    }).then(response => {
      projs = response.data.projects;
      computeWorkedHours(projs);
    });
    setProjects(projs);
  }

  async function computeWorkedHours(projs) {
    let users = []
    setState("Carregando lista de usuários...");
    await clockifyApi.get(`/workspaces/${workspaceId}/users/?status=ACTIVE`, {
      headers: {
        'X-Api-Key': api_key
      },
      params: {
        archived: false,
      }
    }).then(response => {
      users = response.data;
    });

    let firstDayInThisMonth = getFirstDayOfMonth(new Date()).toISOString();
    let workedSeconds = [];
    projs.forEach(function (project) {
      workedSeconds[project.clockify_id] = 0;
    })

    let pageSize = 100;
    for (let i = 0; i < users.length; i++) {
      let page = 1;
      let isLastPage = false;
      while (!isLastPage) {
        setState(`Carregando time-entries de ${users[i].name} [Página ${page}]`);
        await clockifyApi.get(`/workspaces/${workspaceId}/user/${users[i].id}/time-entries`, {
          headers: {
            'X-Api-Key': api_key
          },
          params: {
            start: firstDayInThisMonth,
            page: page,
            "page-size": pageSize,
          }
        }).then(response => {
          response.data.forEach(entry => {
            if (entry.timeInterval.start !== null && entry.timeInterval.end !== null) {
              let start = new Date(entry.timeInterval.start);
              let end = new Date(entry.timeInterval.end);
              let diff = end.getTime() - start.getTime();
              workedSeconds[entry.projectId] += diff / 1000;
            }
          });

          isLastPage = response.data.length < pageSize;
          page++;
        });
      }
    };

    setState("Calculando total de horas...");

    projs.forEach(function (project) {
      let wkdSeconds = workedSeconds[project.clockify_id];
      project.workedSeconds = wkdSeconds;
      project.workedHours = parseFromSecondsToHours(wkdSeconds);
      project = computeProgress(project)
    });

    setState("");
    setProjects(projs);
  }

  function getFirstDayOfMonth(date) {
    return new Date(date.getFullYear(), date.getMonth(), 1);
  }

  function strigify(el) {
    return el < 10 ? "0" + el : "" + el;
  }

  function parseFromSecondsToHours(seconds) {
    let hours = parseInt(seconds / secondsInOneHour);
    let min = parseInt((seconds % secondsInOneHour) / 60);
    return hours + ":" + strigify(min);
  }

  function computeProgress(project) {
    let progress = 0;
    let mensal_hours = parseFloat(project.mensal_hours_limit);
    let mensal_seconds = 0;
    if (mensal_hours <= 0) {
      progress = project.workedSeconds > 0 ? 100 : 0;
    } else {
      mensal_seconds = mensal_hours * secondsInOneHour;
      progress = parseInt(project.workedSeconds * 100 / mensal_seconds);
    }
    project.progress = progress;
    let alert_limit = parseFloat(project.alert_limit) * secondsInOneHour;
    project.progressStyle = computeProgressStyle(progress, project.workedSeconds, alert_limit);

    let workingProgress = 0;
    if (mensal_hours > 0) {
      workingProgress = parseInt(project.workingSeconds * 100 / mensal_seconds);
    }
    project.workingProgress = workingProgress > 0 ?
      "(+" + workingProgress + "%)" : "";
    project.workingProgressStyle = computeProgressStyle(progress + workingProgress, project.workedSeconds + project.workingSeconds, alert_limit);
    return project;
  }

  function computeProgressStyle(progress, workedSeconds, projectSeconds) {
    let style = {}
    style.width = progress + "%";
    if (progress >= 100) {
      style.background = "#d32f2f";
    } else if (workedSeconds >= projectSeconds) {
      style.background = "#4FAFE2";
    }
    return style;
  }

  function handleOpenProjectDetails(project) {
    console.log("---> Opening ", project);
    setModalProject(project);
  };

  const handleClose = () => {
    setModalProject(emptyProject);
  };

  return (
    <div className="report-container">
      <Menu title="Projetos" />
      <div className="content_main_container">
        <div className="header">
          <h1>Projetos</h1>
        </div>

        <div className="report">
          <p>Considerando desde o início do corrente mês</p>
          <p className="state">{state}</p>
          <ul className="ul_loeffa">
            {projects.sort((project_a, project_b) => project_b.workedSeconds - project_a.workedSeconds).map(project => (
              <li key={project.id} onClick={(e) => {
                handleOpenProjectDetails(project);
              }}>
                <h4>{project.name} <span>{project.client.name}</span></h4>
                <span>{project.workedHours} horas trabalhadas. Limite de {project.mensal_hours_limit}</span><br />
                <div className="progressContainer">
                  <div className="progress workingProgress" style={project.workingProgressStyle}></div>
                  <div className="progress workedProgress" style={project.progressStyle}></div>
                  <div className="progress weekdays">
                    <span>Segunda</span>
                    <span>Terça</span>
                    <span>Quarta</span>
                    <span>Quinta</span>
                    <span>Sexta</span>
                  </div>
                  <span>{project.progress}% {project.workingProgress}</span>
                </div>
              </li>
            ))}
          </ul>
          <Dialog
            open={!modalProject.isEmptyProject}
            maxWidth='lg'
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">{modalProject.name}</DialogTitle>
            <DialogContent>
              {modalProject.gitlab !== undefined &&
                <a target="_blank" rel="noopener noreferrer" href={modalProject.gitlab} title="Gitlab" className="external_links"><FiGitlab /></a>
              }
              {modalProject.site !== undefined &&
                <a target="_blank" rel="noopener noreferrer" href={'http://' + modalProject.site} title="Site" className="external_links"><FiGlobe /></a>
              }
              {modalProject.clickup_link !== undefined &&
                <a target="_blank" rel="noopener noreferrer" href={modalProject.clickup_link} title="Clickup" className="external_links"><FiCheckSquare /></a>
              }
              {modalProject.drive !== undefined &&
                <a target="_blank" rel="noopener noreferrer" href={modalProject.drive} title="Drive" className="external_links"><DiGoogleDrive /></a>
              }
              <DialogContentText id="alert-dialog-description">
                <ReactMarkdown source={modalProject.info} />
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary" autoFocus>
                OK
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </div>
    </div>
  );
}

