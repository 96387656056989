import React from 'react';

import './global.css';

import Routes from './routes';
import Footer from './pages/Footer';

function App() {
    return (
      <div className="main-container">
        <div className="main-content">
          <Routes />
        </div>
        <Footer />
      </div>
    );
}

export default App;
