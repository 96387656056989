import React, { useState, useEffect } from 'react';
import { FiRefreshCw } from 'react-icons/fi';
import { utcToZonedTime } from 'date-fns-tz';
import { format, startOfWeek, addDays, endOfMonth } from 'date-fns';
import clockifyApi from '../../services/clockifyApi';
import ptBR from 'date-fns/locale/pt-BR';
import localApi from '../../services/localApi';
import Menu from '../MenuLoeffa'
import './style.css';
import Util from './util.js';
import styles from './style.module.css';
import Loading from './Loading';
import GoalsCard from './GoalsCard.js';
import LogoImg from '../MenuLoeffa/LogoImg.js';
import arrowBack from '../../static/images/arrow_back2.png';
import arrowFront from '../../static/images/arrow_back.png';


export default function Users() {
  const saoPauloTimezone = 'America/Sao_Paulo';
  const [users, setUsers] = useState([]);
  const [userList, setUserList] = useState([]);
  const [weekDays, setWeekDays] = useState(5);
  const [description, setDescription] = useState([]);
  const [state, setState] = useState("");
  const [weekText, setWeektext] = useState("nesta semana.");
  const [currentWeek, setCurrentWeek] = useState(getTodaySaoPaulo());
  const [currentUserWeekGoal, setCurrentUserWeekGoal] = useState("40.0h");
  const [currentUserMonthGoal, setCurrentUserMonthGoal] = useState("180.0h");
  const [currentUserweeklyBalance, setcurrentUserweeklyBalance] = useState("Calculando");
  const [currentUserweeklyBalanceToolTip, setCurrentUserweeklyBalanceToolTip] = useState("Calculando");
  const defaultBillableHours = "calculando ...";
  const [isLoading, setIsLoading] = useState(true);
  const [billableHours, setBillableHours] = useState(defaultBillableHours);
  const [lastMonthWeekDay, setLastMonthWeekDay] = useState('');
  const workspaceId = "5e472200e4acab6e6c05404c";
  const api_key = localStorage.getItem('api_key');
  const token = localStorage.getItem('token');

  const pageSize = 50;
  const secondsInOneHour = 3600;

  useEffect(() => {
    // console.log("---> Carregando...");
    const loadUsersData = async () => {
      await loadUsers()
      currentWeek.setDate(currentWeek.getDate() - currentWeek.getDay());
    }
    loadUsersData();
    getHoursDataForCurrentUser();
  }, []);

  useEffect(() => {
    const lastDayOfWeekInMonth = getLastDayOfWeekInMonth(currentWeek);
    if (isDayInWeek(lastDayOfWeekInMonth, currentWeek)) {
      setLastMonthWeekDay(`${format(lastDayOfWeekInMonth, 'EEEE', { locale: ptBR })}`);
    } else {
      setLastMonthWeekDay('');
    }
  }, [currentWeek]);

  function getLastDayOfWeekInMonth(currentWeek) {
    let lastDayOfMonth = endOfMonth(currentWeek);
    let nextDay = addDays(currentWeek, 1);
    const nextDayMonth = endOfMonth(nextDay);
    if (nextDayMonth !== lastDayOfMonth) lastDayOfMonth = nextDayMonth;
    const lastWeekDayOfCurrentMonth = lastDayOfMonth.getDay();
    if (lastWeekDayOfCurrentMonth === 0) lastDayOfMonth = addDays(lastDayOfMonth, -2);
    if (lastWeekDayOfCurrentMonth === 6) lastDayOfMonth = addDays(lastDayOfMonth, -1);
    return lastDayOfMonth;
  }

  function isDayInWeek(day, week) {
    const startOfWeekDate = startOfWeek(week);
    const endOfWeekDate = addDays(startOfWeekDate, 7);
    const dayToCheck = new Date(day);
    startOfWeekDate.setHours(0, 0, 0, 0);
    endOfWeekDate.setHours(0, 0, 0, 0);
    dayToCheck.setHours(0, 0, 0, 0);
    const startOfWeekTime = startOfWeekDate.getTime();
    const endOfWeekTime = endOfWeekDate.getTime();
    const dayToCheckTime = dayToCheck.getTime();
    return dayToCheckTime >= startOfWeekTime && dayToCheckTime <= endOfWeekTime;
  }

  function getTodaySaoPaulo() {
    return utcToZonedTime(new Date(), saoPauloTimezone);
  }

  useEffect(() => {
    calculateBillibleHours();
    setState("");
    if (users.length > 0) {
      setIsLoading(false);
    }
  }, [users])

  function previousWeek() {
    const newWeek = new Date(currentWeek);
    newWeek.setDate(currentWeek.getDate() - currentWeek.getDay() - 7);
    setCurrentWeek(newWeek);
    loadUsers();
    updateWeekText();
  }

  function nextWeek() {
    const newWeek = new Date(currentWeek);
    newWeek.setDate(currentWeek.getDate() - currentWeek.getDay() + 7);
    setCurrentWeek(newWeek);
    loadUsers();
    updateWeekText();
  }

  function updateWeekText() {
    let today = getTodaySaoPaulo();
    let weekStart = new Date(today.setDate(today.getDate() - today.getDay()));
    let weekEnd = Util.weekEndForDay(weekStart);
    let lastWeekStart = new Date(weekStart.getTime());
    lastWeekStart.setDate(lastWeekStart.getDate() - 7);
    let lastWeekEnd = Util.weekEndForDay(lastWeekStart);

    weekStart.setHours(0, 0, 0);
    weekEnd.setHours(0, 0, 0);
    lastWeekStart.setHours(0, 0, 0);
    lastWeekEnd.setHours(0, 0, 0);

    if (currentWeek >= weekStart && weekEnd >= currentWeek) {
      setWeektext("nesta semana");
    } else if (currentWeek >= lastWeekStart && lastWeekEnd >= currentWeek) {
      setWeektext("semana passada");
    } else {
      setWeektext(`em ${currentWeek.toLocaleDateString("pt-br")}`);
    }
  }

  function update() {
    getWorkedHoursForAllUsers();
  }

  function toFixed(num, fixed) {
    let re = new RegExp('^-?\\d+(?:\.\\d{0,' + (fixed || -1) + '})?');
    return num.toString().match(re)[0];
  }

  async function calculateBillibleHours() {
    let totalBillableHours = 0;

    for (let i = 0; i < users.length; i++) {
      totalBillableHours += users[i].userBillableHours;
    };
    setBillableHours(toFixed(totalBillableHours, 2) + "h");
  }

  async function updateByUser(user) {
    setIsLoading(true)
    setBillableHours(defaultBillableHours);
    let usersCopy = [...users];
    let userUpdated = await getWorkedHours(user);
    let index = users.findIndex(u => u.id === user.id);
    usersCopy[index] = userUpdated;
    setUsers(usersCopy.sort((user_a, user_b) => user_b.workedSeconds - user_a.workedSeconds));
    setIsLoading(false)
  }

  async function loadUsers() {
    let auth = 'Token ' + token;
    const body = {
      currentWeek,
    }
    await localApi.post(`/team/`, body, {
      headers: {
        Authorization: auth
      }
    }).then(response => {
      // console.log(response)
      setUserList(response.data.team)
    });
  }

  useEffect(() => {
    getWorkedHoursForAllUsers()
  }, [userList])

  async function getHoursDataForCurrentUser() {
    let week = currentWeek.toISOString().split("T")[0];
    let auth = 'Token ' + token;
    await localApi.get(`/goals/`, {
      headers: {
        Authorization: auth
      }
    }).then(response => {
      setCurrentUserWeekGoal(response.data["user_week_goal_str"]);
      setCurrentUserMonthGoal(response.data["user_month_goal_str"]);
      setcurrentUserweeklyBalance(response.data["user_weekly_balance"]);
      setCurrentUserweeklyBalanceToolTip(response.data["tooltip_text"]);
    });
  }

  async function getWorkedHoursForAllUsers() {
    setIsLoading(true)
    setBillableHours(defaultBillableHours);

    const promisses = userList.map(async (user) => {
      if (user.WeekGoal !== 0) return await getWorkedHours(user);
    });
    const updatedUsers = await Promise.all(promisses);
    const usersSorted = updatedUsers.sort((user_a, user_b) => user_b.workedSeconds - user_a.workedSeconds)
    setUsers(usersSorted);
  }

  async function getWorkedHours(user) {
    let page = 1;
    let isLastPage = false;
    let workedSeconds = 0;
    let workingSeconds = 0;
    let timeEntriesCount = 0;
    let userBillableHours = 0;
    user.userBillableHours = 0;
    while (!isLastPage) {
      setState(`Carregando time-entries de ${user.name} [Página ${page}]`);
      await clockifyApi.get(`/workspaces/${workspaceId}/user/${user.clockify_id}/time-entries`, {
        headers: {
          'X-Api-Key': api_key
        },
        params: Util.getRequestParams(currentWeek, page, pageSize)
      }).then(response => {
        response.data.forEach(entry => {
          if (entry.timeInterval.end !== null) {
            let start = new Date(entry.timeInterval.start);
            let end = new Date(entry.timeInterval.end);
            let diff = (end.getTime() - start.getTime()) / 1000;
            workedSeconds += diff;
            if (entry.billable) {
              userBillableHours += (diff / secondsInOneHour);
            }
          } else {
            let start = new Date(entry.timeInterval.start);
            let end = getTodaySaoPaulo();
            let diff = (end.getTime() - start.getTime()) / 1000;
            workingSeconds += diff;
            if (entry.billable) {
              userBillableHours += (diff / secondsInOneHour);
            }
          }
        });
        timeEntriesCount += response.data.length;

        if (response.data.length < pageSize) {
          isLastPage = true;
          user.userBillableHours += userBillableHours;
          user.timeEntriesCount = timeEntriesCount;
          user.workedSeconds = workedSeconds;
          user.workingSeconds = workingSeconds;
          user.workedHours = parseFromSecondsToHours(workedSeconds);
          user.workingHours = workingSeconds > 0 ?
            "(+" + parseFromSecondsToHours(workingSeconds) + ")" : null;
          user = computeProgress(user);
        }
        page++;
      }).catch(
        function (error) {
          console.log('O seguinte erro ocorreu ao baixar as horas do usuário ' + user.name + ':')
          console.log(error)
          isLastPage = true
        }
      );
      continue;
    }

    return user;
  }

  function strigify(el) {
    return (el < 10 ? "0" : "") + el;
  }

  function parseFromSecondsToHours(seconds) {
    let hours = parseInt(seconds / secondsInOneHour);
    let min = parseInt((seconds % secondsInOneHour) / 60);
    return hours + ":" + strigify(min);
  }

  async function computeProgress(user) {
    let week = currentWeek.toISOString().split("T")[0];
    let userWorkWeekHours = 0;
    let userWorkWeekHoursStr = "";
    let auth = 'Token ' + token;
    await localApi.get(`/weekgoal/${user.id}/week/${week}`, {
      headers: {
        Authorization: auth
      }
    }).then(response => {
      userWorkWeekHours = response.data["user_week_goal"];
      userWorkWeekHoursStr = response.data["user_week_goal_str"];
      setWeekDays(response.data["week_days"]);
      if (JSON.stringify(response.data['description']) !== JSON.stringify(description)) {
        setDescription(response.data["description"]);
      }
    });
    let userSeconds = userWorkWeekHours * secondsInOneHour;
    let progress = 0;
    if (userSeconds !== 0) {
      progress = parseInt(user.workedSeconds * 100 / userSeconds);
    }
    user.progress = progress;
    user.progressStyle = computeProgressStyle(progress, user.workedSeconds, userSeconds);
    let workingProgress = parseInt(user.workingSeconds * 100 / userSeconds);
    user.workingProgress = workingProgress > 0 ? "(+" + workingProgress + "%)" : "";
    user.workingProgressStyle = computeProgressStyle(progress + workingProgress, user.workedSeconds + user.workingSeconds, userSeconds);
    user.weekGoal = userWorkWeekHours;
    user.userWorkWeekHoursStr = userWorkWeekHoursStr;
    return user;
  }

  function getWeekRangeString(currentWeek) {
    const startOfWeekDate = startOfWeek(currentWeek, { weekStartsOn: 1 });
    let nextMonday = startOfWeekDate;
    while (nextMonday <= currentWeek) {
      nextMonday = addDays(nextMonday, 7);
    }
    let endOfWeekDate = nextMonday;
    while (endOfWeekDate.getDay() !== 5) {
      endOfWeekDate = addDays(endOfWeekDate, 1);
    }
    const startFormatted = format(nextMonday, 'dd/MM/yyyy');
    const endFormatted = format(endOfWeekDate, 'dd/MM/yyyy');
    const rangeString = `Dia ${startFormatted} até ${endFormatted}`;
    return rangeString;
  }

  function computeProgressStyle(progress, workedSeconds, userSeconds) {
    let style = {}
    if (isNaN(progress)) {
      progress = 0;
    }
    style.width = progress + "%";
    if (workedSeconds >= userSeconds) {
      style.background = "#388e3c";
    }
    return style;
  }
  return (
    <div className={`report-container ${styles.main_panel_container}`}>
      <Menu title="Metas" />
      <div className={styles.content_main_container}>
        <div className={`header ${styles.header_container}`}>
          <h1 className={styles.header_title}>Metas de Horas Semanais</h1>
          <div className={styles.header_buttons}>
            <p>{getWeekRangeString(currentWeek)}</p>
            <div>
              <button onClick={previousWeek} className="date-changer" type="button">
                <LogoImg src={arrowBack} alt="semana anterior" styles={styles.arrow_icon} />
              </button>
              <button onClick={nextWeek} className="date-changer" type="button">
                <LogoImg src={arrowFront} alt="semana seguinte" styles={styles.arrow_icon} />
              </button>
            </div>
          </div>
        </div>
        <div className="report">
          {isLoading ? (
            <Loading />
          ) : (
            <>
              <div className={styles.month_infos}>
                {lastMonthWeekDay && (
                  <p><strong>O mês atual irá terminar {lastMonthWeekDay}. | </strong></p>)}
                <p>Total de horas cobráveis: {billableHours}</p>
              </div>
              <p>
                <strong>Considerando {weekDays} dias úteis {weekText}</strong>
                <FiRefreshCw onClick={update} className="update" style={{ marginLeft: '8px' }} />

                {description.length > 0 &&
                  <span>
                    <br />
                    {description.join(", ")}
                  </span>
                }
              </p>
              <p className="state">{state}</p>
              <div className={styles.content_container}>
                <ul className="ul_loeffa">
                  {users?.map(user => (
                    <li key={user.id}>
                      <h4>{user.name}</h4>
                      <FiRefreshCw className="update" onClick={() => { updateByUser(user) }} />
                      <span>{user.workedHours} {user.workingHours} horas trabalhadas em {user.timeEntriesCount} time entries (meta {user.userWorkWeekHoursStr}h)</span><br />
                      <div className="progressContainer">
                        <div className="progress workingProgress" style={user.workingProgressStyle}></div>
                        <div className="progress workedProgress" style={user.progressStyle}></div>
                        <div className="progress weekdays" style={user.weekdays}>
                          <span>Segunda</span>
                          <span>Terça</span>
                          <span>Quarta</span>
                          <span>Quinta</span>
                          <span>Sexta</span>
                        </div>
                        <span>{user.progress}% {user.workingProgress}</span>
                      </div>
                    </li>
                  ))}
                </ul>
                <div className={styles.my_goals_container}>
                  <h1 className={styles.my_goals_title}>Minhas metas</h1>
                  <div className={styles.goals_card_main_container}>
                    <GoalsCard titleText="Meta semanal" time={currentUserWeekGoal} styles={styles} />
                    <GoalsCard titleText="Meta Mensal" time={currentUserMonthGoal} styles={styles} />
                    <GoalsCard titleText="Saldo da semana" time={currentUserweeklyBalance} styles={styles} tooltipText={currentUserweeklyBalanceToolTip} />
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}