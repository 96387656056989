import React, { useState } from 'react';

function GoalsCard({titleText, time, styles, tooltipText}) {
  const [showTooltip, setShowTooltip] = useState(false);

  const handleMouseEnter = () => {
    if (tooltipText) {
      setShowTooltip(true);
    }
  };

  const handleMouseLeave = () => {
    if (tooltipText) {
      setShowTooltip(false);
    }
  };

  return (
    <div
      className={styles.goals_card_container}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <h1 className={styles.goals_card_title}>{titleText}</h1>
      <p className={styles.goals_card_text}>{time}</p>
      {tooltipText && showTooltip && (
        <div className={styles.tooltip}>
          {tooltipText}
        </div>
      )}
    </div>
  );
}

export default GoalsCard;