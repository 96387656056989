import React from 'react'

function StatusDescription({descriptionText}) {
  return (
    <p className="status-description">
      {descriptionText}
    </p>
  )
}

export default StatusDescription;