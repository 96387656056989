import React from 'react'


function handleLogout() {
  localStorage.clear()
}

function SideBarHeader({ title, userName, styles}) {
  return (
    <div>
      <div className={`${styles.header_texts} ${styles.sidebar_header}`}>
        <h3 className={styles.sidebar_title}>Painel Loeffa</h3>
        <p className={styles.sidebar_user}>{userName}</p>
        <p><span>Meta de horas | <a className={styles.header_link} href="/" onClick={handleLogout}>Logout</a></span></p>
      </div>
      <hr/>
    </div>
  )
}

export default SideBarHeader