import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { Sidebar, Menu, MenuItem } from 'react-pro-sidebar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import logo from '../../static/images/logo_negativa.png'
import SideBarItem from './SideBarItem';
import timer from '../../static/images/timer.png';
import groupLogo from '../../static/images/groups.png';
import SideBarHeader from './SideBarHeader';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';

import LogoImg from './LogoImg';
import projectsLogo from '../../static/images/insert_chart_outlined.png';
import logoutLogo from '../../static/images/logout.png';
import styles from './style.module.css';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
}));

export default function ButtonAppBar({ title }) {
  const classes = useStyles();
  const userName = localStorage.getItem('userName') || "";
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [modalLogout, setModalLogout] = useState(false);
  const history = useHistory();

  function handleLogout() {
    localStorage.clear();
    history.push("/");
    setModalLogout(false);
  }

  function handleModalLogoutClose() {
    setModalLogout(false);
  }

  function handleModalLogoutOpen() {
    setModalLogout(true);
  }

  const handleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  }

  const menuLinkStyles = {
    containerStyles: (isCollapsed ? styles.collapsed_links : styles.container_links),
    labelStyles: styles.label_links,
    imageStyles: styles.image_links,
  }

  const PersonMenuLinks = [
    { label: 'Dashboard', link: '/dashboard', image: projectsLogo, alt: "Inicio", ...menuLinkStyles },
    { label: 'Metas Semanais', link: '/users', image: timer, alt: "Metas", ...menuLinkStyles },
  ];

  const ProjectsMenuLinks = [
    { label: 'Relatórios', link: '/projects', image: projectsLogo, alt: "Projetos", ...menuLinkStyles },
  ];

  const ConfigurationsMenuLinks = [
    { label: "Admin", link: `http://${window.location.hostname.replace("clockify.", "")}`, image: groupLogo, isAdminLink: true, alt: "Admin", ...menuLinkStyles },
    { label: 'Logout', link: '#', image: logoutLogo, alt: "Logout", onClick: handleModalLogoutOpen, ...menuLinkStyles },
  ];

  return (
    <div className={classes.root}>
      <AppBar position="static" style={{ backgroundColor: 'rgba(79, 175, 226, 1)' }}>
        <Toolbar>
          <IconButton onClick={handleCollapse} edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
            <MenuIcon />
          </IconButton>
          <LogoImg src={logo} alt="Loeffa" styles={styles.form_image} />
        </Toolbar>
      </AppBar>
      <div className={styles.content_container}>
        <Sidebar collapsed={isCollapsed} width="245px" collapsedWidth="127px" className={(!isCollapsed ? styles.side_bar : styles.side_bar_collapsed)}>
          <Menu>
            {!isCollapsed && (
              <SideBarHeader title={title} userName={userName} styles={styles} />
            )}

            {!isCollapsed && <MenuItem>Pessoas</MenuItem>}
            {PersonMenuLinks.map((link, index) => (
              <SideBarItem key={index} {...link} />
            ))}

            {!isCollapsed && <MenuItem>Projetos</MenuItem>}
            {ProjectsMenuLinks.map((link, index) => (
              <SideBarItem key={index} {...link} />
            ))}

            {!isCollapsed && <MenuItem>Configurações</MenuItem>}
            {ConfigurationsMenuLinks.map((link, index) => (
              <SideBarItem key={index} {...link} />
            ))}
          </Menu>
        </Sidebar>
      </div>
      <Dialog
        open={modalLogout}
        maxWidth='lg'
        onClose={handleModalLogoutClose}>
        <DialogTitle id="alert-dialog-title">Você tem certeza que quer sair?</DialogTitle>
        <DialogActions>
          <Button onClick={handleLogout} color="primary" autoFocus>SIM</Button>
          <Button onClick={handleModalLogoutClose}>NÃO</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
