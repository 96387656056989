import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import clockifyApi from '../../services/clockifyApi';
import localApi from '../../services/localApi';

import './style.css';

export default function Logon() {
    const [api_key, setApi_key] = useState('');
    const history = useHistory();

    if (localStorage.getItem('api_key')) {
        history.push('users');
    }

    async function setClockifyApi() {
        let auth = 'Token ' + localStorage.getItem('token');
        let data = { clockify_api: api_key };

        await localApi.post('set-clockify-api/', data, {
            headers: { Authorization: auth }
        });
        console.log("---> Clockify Api adicionada com sucesso")
    }

    async function checkClockifyApi() {
        await clockifyApi.get('user', {
            headers: {
                'X-Api-Key': api_key
            }
        });
        localStorage.setItem('api_key', api_key);
    }

    async function handleLogin(e) {
        e.preventDefault();

        try {
            await checkClockifyApi();
            await setClockifyApi();
            history.push('users');
        } catch (err) {
            alert('Falha no login, tente novamente...');
        }
    }

    return (
        <div className="logon-container">
            <form onSubmit={handleLogin}>
                <h1>Faça seu logon</h1>

                <p>Utilize sua <a href="https://app.clockify.me/user/settings" target="_blank" rel="noopener noreferrer">API Key</a> do Clockify para acessar.</p>

                <input
                    placeholder="Sua API Key"
                    value={api_key}
                    onChange={e => setApi_key(e.target.value)}
                />

                <button className="button logon-button" type="submit">Entrar</button>
            </form>
        </div>
    );
}