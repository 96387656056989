import React from 'react'

function ResetPasswordInput({ type = "text", value, onChange, placeholder, id = "", labelText, required}) {
  return (
    <div className='input-container'>
      { labelText && (<label className="input-reset-label" for={id}>{labelText}</label>) }
    <input
      type={type}
      id={id}
      className="input-reset"
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      required
    />
    </div>
  )
}

export default ResetPasswordInput;