import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import LoginInput from '../Login/LoginInput';

import localApi from '../../services/localApi';

import './style.css';
import styles from './ForgotPassword.module.css'

export default function ForgotPassword() {
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [forgotPasswordSuccess, setforgotPasswordSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const history = useHistory();

  if (localStorage.getItem('token')) {
    history.push('forgotpassword');
  }

  async function loginReturn() {
    history.push('/')
  }

  const resetEmail = () => {
    setEmail("");
  }


  async function requestPasswordReset() {
    setIsLoading(true);
    if (email.length === 0) {
      setErrorMessage("O campo de email não pode ficar vazio!");
    } else {
      try {
        await localApi.post('password_reset/',
        {
          "email": email
        }).then(() => {
          resetEmail()
          setforgotPasswordSuccess(true);
        }).catch(() => {
          setforgotPasswordSuccess(false);
          resetEmail();
          setErrorMessage("Erro, email inválido!");
        });
      } catch (err) {
        setforgotPasswordSuccess(false);
        resetEmail();
        setErrorMessage("Erro ao enviar email, tente novamente");
      }
    }
    setIsLoading(false);
  }

  return (
    <div className={styles.main_panel_container}>
      <div style={{ display: isLoading ? 'flex' : 'none' }} className='modal'>
        <div className='modal-content'>
          <div className='loader'></div>
          <div className='modal-text'>Espere ...</div>
        </div>
      </div>
      <div className={styles.forgot_password_container}>
        <form style={{ display: forgotPasswordSuccess ? 'none' : 'block' }}>
          <div className="form-container">
            <br/>
            <h1 className="title">Esqueci a senha</h1>
            <br/>
            <p className={styles.error_message}>{errorMessage}</p>
            <LoginInput
              placeholder="seunome@loeffa.com"
              id="email"
              labelText="E-mail"
              value={email}
              onChange={e => setEmail(e.target.value)}
            />
            <div>
              <strong>Enviaremos um e-mail com instruções para a redefinição da sua senha</strong>
            </div>
            <br/>
            <div className={styles.forgot_password_button}>
              <button type="button"  onClick={requestPasswordReset}>Enviar E-Mail</button>
            </div>
            <div className={styles.return_to_login} >
              <button type="button" className="return" onClick={loginReturn}>Voltar</button>
            </div>
          </div>
        </form>
        <div style={{ display: forgotPasswordSuccess ? 'block' : 'none' }} className="form-container">
          <br/>
            <h1 className="title">Esqueci a senha</h1>
          <br/>
          <div>
            <strong>Feito! Um e-mail foi enviado com instruções para você redefinir sua nova senha</strong>
          </div>
          <div className={styles.return_to_login} >
            <button type='button' className="return" onClick={loginReturn}>Voltar</button>
          </div>
        </div>
      </div>
    </div>
  );
}