
export default class Util {

    static weekStartForDay(date) {
        date = new Date(date.getTime()); // clones date, to keep side effects handled

        const SUNDAY = 0; // https://developer.mozilla.org/pt-BR/docs/Web/JavaScript/Reference/Global_Objects/Date/getDay
        const initialWeekDay = SUNDAY;

        date.setHours(0, 0, 0, 0);
        let weekDay = date.getDay();
        date.setHours(-24 * (weekDay - initialWeekDay));

        return date;
    }

    static weekEndForDay(date) {
        date = new Date(date.getTime()); // clones date, to keep side effects handled
        date.setDate(date.getDate() + 7);
        return this.weekStartForDay(date);
    }

    static formatToISOWithBrazilTimezone(date) {
        const pad = function (num) {
            return (num < 10 ? '0' : '') + num;
        };

        const year = date.getFullYear();
        const month = pad(date.getMonth() + 1);
        const day = pad(date.getDate());
        const hours = pad(date.getHours());
        const minutes = pad(date.getMinutes());
        const seconds = pad(date.getSeconds());

        const offsetHours = 3;

        return (
            year +
            '-' + month +
            '-' + day +
            'T' + hours +
            ':' + minutes +
            ':' + seconds +
            `.000-0${offsetHours}:00`
        );
    }
    
    static getRequestParams(weekStart, page, pageSize) {
        let weekEnd = this.weekEndForDay(weekStart);
        weekStart.setHours(0, 0, 0, 0);

        // Format to Brazil timezone
        weekStart.toLocaleString("en-US", { timeZone: 'America/Sao_Paulo' });
        weekEnd.toLocaleString("en-US", { timeZone: 'America/Sao_Paulo' });

        let isoWeekStart = this.formatToISOWithBrazilTimezone(weekStart);
        let isoWeekEnd = this.formatToISOWithBrazilTimezone(weekEnd);

        let object = {
            "start": isoWeekStart,
            "end": isoWeekEnd,
            "page": page,
            "page-size": pageSize,
        };
        return object; 
    }

    static toIsoString(date) {
        let pad = function (num) {
            let norm = Math.floor(Math.abs(num));
            return (norm < 10 ? '0' : '') + norm;
        };

        return date.getFullYear() +
            '-' + pad(date.getMonth() + 1) +
            '-' + pad(date.getDate()) +
            'T' + pad(date.getHours()) +
            ':' + pad(date.getMinutes()) +
            ':' + pad(date.getSeconds()) +
            ".000Z"
    }
}