import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import Login from './pages/Login';
import Logon from './pages/Logon';
import PasswordReset from './pages/PasswordReset';
import Users from './pages/Users';
import Projects from './pages/Projects';
import Dashboard from './pages/Dashboard/Dashboard'
import StatusService from './pages/StatusService';
import ForgotPassword from './pages/ForgotPasswordRequest';

export default function Routes() {
    return (
        <BrowserRouter>
            <Switch>
                <Route path="/" exact component={Login} />
                <Route path="/logon" exact component={Logon} />
                <Route path="/forgotpassword" exact component={ForgotPassword} />
                <Route path="/passwordreset" exact component={PasswordReset} />
                <Route path="/passwordreset/:status" exact component={StatusService} />
                <Route path="/users" exact component={Users} />
                <Route path="/projects" exact component={Projects} />
                <Route path="/dashboard" exact component={Dashboard} />
            </Switch>
        </BrowserRouter>
    );
}