import React from 'react'
import { useHistory } from 'react-router-dom';
import StatusTitle from './StatusTitle'
import StatusDescription from './StatusDescription'
import StatusButton from './StatusButton'

function SuccessReset() {
    const history = useHistory();

    const handleClick = () => {
        history.push('/');
    }
  const successText = "A senha foi alterada com sucesso! Por favor, realize o login novamente."
  return (
  <div className='status-container'>
    <StatusTitle titleText="Redefinir senha" />
    <StatusDescription descriptionText={successText} />
    <StatusButton buttonText="Fazer Login" handleClick={handleClick} />
  </div>
  )
}

export default SuccessReset