import React from 'react';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';

import './style.css';

export default function SimpleCard({name, image, alt}) {

    return (
        <Card variant="outlined" className="Card">
            <CardContent >
                <img className="imagem_card" alt={alt} src={image} />
            </CardContent>
            <CardActions className="texto_card">
                <Button size="medium" > {name}</Button>
            </CardActions>
        </Card>
    );
}
