import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import LogoImg from './LogoImg';
import LoginTitle from './LoginTitle';
import LoginInput from './LoginInput';

import localApi from '../../services/localApi';

import './style.css';
import styles from './Login.module.css'
import LoginButton from './LoginButton';

export default function Logon() {
  const [email, setEmail] = useState('');
  const [password, setPassord] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();

  if (localStorage.getItem('token')) {
    history.push('logon');
  }

  async function getClockifyApi(token) {
    try {
      let auth = 'Token ' + token;
      await localApi.get('retrieve-clockify-api', {
        headers: {
          Authorization: auth
        }
      }).then(response => {
        if (response.data.clockify_api !== "") {
          localStorage.setItem('api_key', response.data.clockify_api);
        }
        localStorage.setItem('userName', response.data.username);
      });
    } catch (err) {
      console.log("---> Erro ao pegar clockify api");
    }
  }

  function forgotPassword() {
    history.push('forgotpassword');
  };

  async function handleLogin(e) {
    setIsLoading(true);
    e.preventDefault();
    try {
      const response = await localApi.post('api-token-auth/', {
        email: email,
        password: password,
      });
      localStorage.setItem('token', response.data.token);
      await getClockifyApi(response.data.token);
      history.push('logon');
    } catch (err) {
      alert('Falha no login, tente novamente...');
    }
    setIsLoading(false);
  }

  return (
    <div className={styles.main_panel_container}>
      <div style={{ display: isLoading ? 'flex' : 'none' }} className='modal'>
        <div className='modal-content'>
          <div className='loader'></div>
          <div className='modal-text'>Espere ...</div>
        </div>
      </div>
      <div className={styles.logon_container}>
        <form onSubmit={handleLogin}>
          <div className={styles.form_container}>
            <LogoImg />
            <LoginTitle />

            <LoginInput
              placeholder="seunome@loeffa.com"
              id="username"
              labelText="E-mail"
              value={email}
              onChange={e => setEmail(e.target.value)}
            />
            <LoginInput
              type="password"
              id="password"
              labelText="Senha"
              placeholder="********"
              value={password}
              onChange={e => setPassord(e.target.value)}
            />
            <div className={styles.forgot_password_container}>
              <button type="button" className={styles.forgot_password} onClick={forgotPassword}>ESQUECI A SENHA</button>
            </div>
            <LoginButton className="login-button" type="submit" content="Entrar" />
          </div>
        </form>
      </div>
    </div>
  );
}